/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _, { set } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Form, Input, Select, Checkbox, Typography, Space } from 'antd';
import ReactGA from 'react-ga';
import { EditFilled } from '@ant-design/icons';
import BorderButton from './BorderButton';

import privacyPolicy from '../../img/img/privacyoptions123x59.png';

// import { gtagConversion } from '../../util/gtag';
import config from '../../config';

import NpiLookup from './NpiLookup';
import registerAndProfileFormStyles from '../../Styles/RegisterAndProfileForm.module.scss';

const { TextArea } = Input;
const { Option } = Select;

const { Text, Title, Link } = Typography;

const Styles = {
  tailLayout: {
    wrapperCol: {
      offset: 0,
      span: 24
    }
  },
  formItemStyle: {
    width: '50%',
    marginRight: 5
  },
  tag: {
    background: '#dfeaef',
    marginTop: 10
  }
};

const requiredRule = {
  required: true,
  message: 'This field is required!'
};

const { skVeevaApiUrl } = config;

const RegisterAndProfileForm = (props) => {
  const {
    formType,
    user,
    userFromLookup,
    regSessionType,
    form,
    error,
    errorText,
    profileFormActive,
    setProfileFormActive,
    dropDownValues,
    submitting,
    isVirtualHybrid
  } = props;
  const isRegForm = formType === 'register';

  // eslint-disable-next-line import/no-named-as-default-member
  const code = config.getUrlParams('code');

  React.useEffect(() => {
    if (userFromLookup) {
      form.setFieldsValue({
        ...userFromLookup,
        specialty: userFromLookup.specialties,
        degree: userFromLookup.degrees,
        communication_consent: true
      });
    }
  }, [userFromLookup]); // eslint-disable-line

  // const [isEligible, setIsEligible] = React.useState(true);
  const [otherSpecialtyDisabled, setOtherSpecialtyStatus] = React.useState(true);
  const [otherDegreeDisabled, setOtherDegreeStatus] = React.useState(true);
  const [otherReferralVisible, setOtherReferralVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [attendanceType, setAttendanceType] = React.useState(null);

  const [isNpiFieldDisabled, setIsNpiFieldDisabled] = React.useState(false);

  const [showNpiLookupCopy, setShowNpiLookupCopy] = React.useState(false);
  const [npiLookupModalOpen, setNpiLookupModalOpen] = React.useState(false);
  const [npiLookupEmpty, setNpiLookupEmpty] = React.useState(false);
  const [isNpiLookup, setIsNpiLookup] = React.useState(false);

  const toggleEdit = () => {
    setEditMode(!editMode);
    ReactGA.event({
      category: 'Profile',
      action: !profileFormActive ? 'Profile Edit Opened' : 'Profile Edit Closed'
    });
    setProfileFormActive(!profileFormActive);
  };

  // const onFormFinishFailed = (errorInfo) => {
  //   console.log('onEmailFormFinishFailed Failed:', errorInfo);
  // };

  useEffect(() => {
    if (profileFormActive && user.attendee_type === 6) {
      form.setFieldsValue({ npi: null });
      setIsNpiFieldDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileFormActive]);

  const onVirtualHybridChange = (label) => {
    return (e) => {
      if (e.target.checked) {
        setAttendanceType(label);
      }
    };
  };

  const onValuesChange = (values) => {
    // setIsEligible(!(regSessionType === 'Out-Of-Office' && values.attendee_type === 7));

    setShowNpiLookupCopy(form.getFieldValue('attendee_type') === 1);

    if (values.attendee_type) {
      const isNonHCP = values.attendee_type === 6;
      setIsNpiFieldDisabled(isNonHCP);
      if (isNonHCP) {
        form.setFieldsValue({ npi: null });
      }
    }

    if (values.specialty !== undefined) {
      // console.log('values.specialty', values.specialty);
      if (_.includes(values.specialty, 'Other')) {
        otherSpecialtyDisabled && setOtherSpecialtyStatus(false);
      } else {
        !otherSpecialtyDisabled && setOtherSpecialtyStatus(true);
      }
    }
    if (values.degree !== undefined) {
      if (_.includes(values.degree, 'Other')) {
        otherDegreeDisabled && setOtherDegreeStatus(false);
      } else {
        !otherDegreeDisabled && setOtherDegreeStatus(true);
      }
    }
    if (values.attendeereferral !== undefined) {
      if (values.attendeereferral === 'Other') {
        !otherReferralVisible && setOtherReferralVisible(true);
      } else {
        otherReferralVisible && setOtherReferralVisible(false);
      }
    }
  };

  const openNpiLookupModal = () => {
    const requiredFields = ['first_name', 'last_name', 'city', 'state', 'zip_code'];
    const isValid = _.every(_.values(_.pick(form.getFieldsValue(), requiredFields)));
    if (isValid) {
      setNpiLookupModalOpen(true);
    } else {
      form.validateFields(requiredFields);
    }
  };

  const setNpiInfo = (hcpInfo) => {
    if (!hcpInfo) {
      setNpiLookupEmpty(true);
      setTimeout(() => {
        setNpiLookupEmpty(false);
      }, 5000);
    } else {
      form.setFieldsValue({ ...hcpInfo });
      setIsNpiLookup(true);
    }
    setNpiLookupModalOpen(false);
  };

  const initialValues = user
    ? {
        ...user,
        specialty: user.specialties,
        degree: user.degrees,
        communication_consent: false,
        patients_treatment_agreement: false,
        speaker_relation_certification: false
      }
    : {
        communication_consent: false,
        patients_treatment_agreement: false,
        speaker_relation_certification: false
      };
  // const idRoleCheckboxHandler = (event) => {};

  // console.log('dropdownValues', dropDownValues);

  const localOnFormFinish = (values) => {
    values.attendanceType = attendanceType;
    if (isNpiLookup) {
      const veevaData = {
        npi: values.npi,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        address_line_1: values.address,
        address_line_2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zip_code
      };
      fetch(skVeevaApiUrl, {
        method: 'POST',
        body: JSON.stringify(veevaData),
        headers: { 'Content-Type': 'application/json' }
      });
    }
    setIsNpiLookup(false);
    props.onFormFinish(values);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="register_form"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      onFinish={localOnFormFinish}
      // onFinishFailed={onFormFinishFailed}
      scrollToFirstError
      className="profile-reg-form"
    >
      <NpiLookup
        formValues={form.getFieldsValue()}
        modalOpen={npiLookupModalOpen}
        setNpiInfo={setNpiInfo}
        handleModalClose={() => setNpiLookupModalOpen(false)}
      />

      <Space direction="vertical" style={{ width: '100%' }}>
        {isRegForm && (
          <Form.Item>
            <Title id={'register-form-title'} style={{ marginBottom: 0 }} level={4}>
              Are you visiting XCOPRI NOW for the first time?
            </Title>
            <Text
              id={'register-form-summary'}
              style={{
                marginBottom: 5,
                marginTop: 5,
                display: 'block',
                lineHeight: 1.2
              }}
            >
              In order to register for the specific program that interests you, as well as access XCOPRI NOW&apos;s many
              useful features, please fill out the form below.
              <span
                style={{
                  color: '#5B2C86',
                  fontWeight: 800,
                  fontSize: 16
                }}
              >
                {' '}
                You&apos;ll only have to register once for full access to XCOPRI NOW.
              </span>
            </Text>
            <Text id={'register-form-splat'}>
              <span>*</span>Indicates a required field
            </Text>
          </Form.Item>
        )}

        <div
          style={
            !isRegForm
              ? {
                  padding: 30,
                  backgroundColor: profileFormActive ? '#f5dee1' : 'white'
                }
              : {}
          }
        >
          {!isRegForm && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              *Indicates a required field
              <Link onClick={toggleEdit}>
                Edit <EditFilled />
              </Link>
            </div>
          )}
          <div className="flex">
            <Form.Item
              label="First Name"
              name="first_name"
              required
              style={Styles.formItemStyle}
              rules={[requiredRule]}
            >
              <Input maxLength={35} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Middle Name" name="middle_name" style={Styles.formItemStyle}>
              <Input disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item label="Last Name" name="last_name" required style={Styles.formItemStyle} rules={[requiredRule]}>
              <Input maxLength={35} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Suffix" name="suffix" style={Styles.formItemStyle}>
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item
              label="City"
              name="city"
              required
              rules={[requiredRule]}
              style={{ width: '33%', marginRight: 5 }}
            >
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              required
              rules={[requiredRule]}
              style={{ width: '33%', marginRight: 5 }}
            >
              <Select placeholder={'Select a State'} disabled={!isRegForm && !profileFormActive}>
                {dropDownValues.states &&
                  dropDownValues.states.map((state) => {
                    return (
                      <Option key={state.abrv} value={state.abrv}>
                        {state.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="ZIP"
              name="zip_code"
              required
              rules={[
                requiredRule,
                {
                  validator: (rule, value) => {
                    if (
                      (value && value.length < 5) ||
                      (value && value.length > 10) ||
                      (value && !_.isNumber(parseInt(value.trim(), 10)))
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Enter 5 digits.');
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              style={{ width: '33%', marginRight: 5 }}
            >
              <Input disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item
              label="Identify the role that best describes you (select one):"
              required
              rules={[requiredRule]}
              name="attendee_type"
              style={Styles.formItemStyle}
            >
              <Select disabled={!isRegForm && !profileFormActive}>
                <Option value={1}>Licensed Healthcare Provider (prescriber with NPI number)</Option>
                <Option value={6}>Non-Licensed HCP (Non-Prescriber)</Option>
              </Select>
            </Form.Item>

            <div className="npiFormCheck" style={{ ...Styles.formItemStyle }}>
              <Form.Item
                label="NPI"
                name="npi"
                required={form.getFieldValue('attendee_type') === 1}
                rules={[
                  ({ getFieldValue }) => ({
                    validator: (rule, value) => {
                      if (getFieldValue('attendee_type') === 1) {
                        if (!value) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('NPI is required.');
                        }
                        if (value && !/^\d{10}$/.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('NPI needs to be 10 digits.');
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
                // style={{ ...Styles.formItemStyle }}
              >
                <Input disabled={(!isRegForm && !profileFormActive) || isNpiFieldDisabled} />
              </Form.Item>
              {showNpiLookupCopy && (
                <p>
                  Please fill in the above information (name and address) and{' '}
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={openNpiLookupModal}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  >
                    click here
                  </span>
                  . The system will look up your information and autofill the remainder of the form, including your NPI
                  number.
                </p>
              )}
              {npiLookupEmpty && <h3 style={{ color: 'red' }}>Sorry, no results found. </h3>}
            </div>
          </div>
          <div className="flex" style={{ justifyContent: 'space-between' }}>
            <Form.Item
              label="Specialty"
              name="specialty"
              required
              rules={[requiredRule]}
              style={{ ...Styles.formItemStyle }}
            >
              <Select mode="multiple" placeholder={'Click here to search'} disabled={!isRegForm && !profileFormActive}>
                {dropDownValues.specialties &&
                  dropDownValues.specialties.map((specialty) => {
                    return (
                      <Option value={specialty} key={specialty}>
                        {specialty}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Degree or Position"
              name="degree"
              required
              rules={[requiredRule]}
              style={Styles.formItemStyle}
            >
              <Select mode="multiple" placeholder={'Please select'} disabled={!isRegForm && !profileFormActive}>
                {dropDownValues.degrees &&
                  dropDownValues.degrees.map((degree) => {
                    return (
                      <Option value={degree} key={degree}>
                        {degree}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>

          {/* <div className="flex">
            <Form.Item
              label="Other Specialty"
              name="other_specialty"
              style={Styles.formItemStyle}
              required={!otherSpecialtyDisabled}
              rules={!otherSpecialtyDisabled && [requiredRule]}
            >
              <Input
                maxLength={50}
                disabled={
                  (isRegForm && otherSpecialtyDisabled) ||
                  (!isRegForm && otherSpecialtyDisabled && !profileFormActive)
                }
              />
            </Form.Item>

            <Form.Item
              label="Other Degree"
              name="other_degree"
              style={Styles.formItemStyle}
              required={!otherDegreeDisabled}
              rules={!otherDegreeDisabled && [requiredRule]}
            >
              <Input
                maxLength={50}
                disabled={
                  (isRegForm && otherDegreeDisabled) ||
                  (!isRegForm && !profileFormActive && otherDegreeDisabled)
                }
              />
            </Form.Item>
          </div> */}

          <Form.Item label="Practice Name/Affiliation" name="affiliation" required rules={[requiredRule]}>
            <Input maxLength={100} disabled={!isRegForm && !profileFormActive} />
          </Form.Item>

          <div className="flex">
            <Form.Item
              label="Office Address"
              name="address"
              required
              rules={[requiredRule]}
              style={Styles.formItemStyle}
            >
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Address 2" name="address2" style={Styles.formItemStyle}>
              <Input maxLength={50} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item label="Licensed State" name="licensed_state" style={Styles.formItemStyle}>
              <Select placeholder={'Select a State'} disabled={!isRegForm && !profileFormActive}>
                {dropDownValues.states &&
                  dropDownValues.states.map((state) => {
                    return (
                      <Option key={state.abrv} value={state.abrv}>
                        {state.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="License #" name="license_number" style={Styles.formItemStyle}>
              <Input maxLength={30} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item label="Cell Phone" name="phone" required rules={[requiredRule]} style={Styles.formItemStyle}>
              <Input maxLength={15} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            <Form.Item label="Fax" name="fax" style={Styles.formItemStyle}>
              <Input maxLength={15} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item label="Home Phone" name="home_phone" style={Styles.formItemStyle}>
              <Input maxLength={15} disabled={!isRegForm && !profileFormActive} />
            </Form.Item>

            {/* This is the second email field */}
            {isRegForm && (
              <Form.Item label="Email" name="email" required rules={[requiredRule]} style={Styles.formItemStyle}>
                <Input maxLength={100} />
              </Form.Item>
            )}
          </div>

          <Form.Item
            label="Dietary Restrictions"
            name="dietary_restrictions"
            rules={[
              {
                validator: (rule, value) => {
                  if (value && value.length > 50) {
                    return Promise.reject(new Error('This field can have a maximum of 50 characters'));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TextArea rows={3} disabled={!isRegForm && !profileFormActive} />
          </Form.Item>

          <Form.Item
            label="Comments"
            name="comments"
            rules={[
              {
                validator: (rule, value) => {
                  if (value && value.length > 50) {
                    return Promise.reject(new Error('This field can have a maximum of 50 characters'));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TextArea rows={3} disabled={!isRegForm && !profileFormActive} />
          </Form.Item>

          {isRegForm && (
            <>
              <Form.Item
                name="patients_treatment_agreement"
                valuePropName="checked"
                rules={[
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    validator: (__, value) => {
                      return value ? Promise.resolve() : Promise.reject(new Error('Please agree to the above'));
                    }
                  }
                ]}
                style={{ textAlign: 'left', marginBottom: 0 }}
              >
                <Checkbox
                  style={{ marginLeft: 0, lineHeight: 1.1 }}
                  className={`${registerAndProfileFormStyles['bottom-checkbox-consent']}`}
                >
                  <b>
                    I confirm that I treat patients over the age of 18 in my practice who have partial-onset seizures.*
                  </b>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="speaker_relation_certification"
                valuePropName="checked"
                rules={[
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    validator: (__, value) => {
                      return value ? Promise.resolve() : Promise.reject(new Error('Please agree to the above'));
                    }
                  }
                ]}
                style={{ textAlign: 'left', marginBottom: 0 }}
              >
                <Checkbox
                  style={{ marginLeft: 0, lineHeight: 1.1 }}
                  className={`${registerAndProfileFormStyles['bottom-checkbox-consent']}`}
                >
                  <b>
                    I certify that I am not an employee of the speaker&apos;s own medical practice (same physical
                    location) or a staff member of a facility for which the speaker is a medical director (whose job
                    function it is to educate those within her or his supervisory authority).
                  </b>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="communication_consent"
                valuePropName="checked"
                rules={[
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    validator: (__, value) => {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Please agree to the communication consent'));
                    }
                  }
                ]}
                style={{ textAlign: 'left' }}
              >
                <Checkbox
                  style={{ marginLeft: 0, lineHeight: 1.1 }}
                  className={`${registerAndProfileFormStyles['bottom-checkbox-consent']}`}
                >
                  <b>
                    I understand and agree that any information that I provide may be used by SKLSI in accordance with
                    SKLSI&apos;s{' '}
                    <a
                      href="https://www.sklifescienceinc.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#f78d2a', textDecoration: 'underline' }}
                    >
                      Privacy Policy
                    </a>
                    . I understand I may opt out of receiving information within future communications.*
                    <br />
                    <br />
                    *By submitting this form, I agree to the site&apos;s{' '}
                    <a
                      href="https://www.sklifescienceinc.com/terms-of-use/ "
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#f78d2a', textDecoration: 'underline' }}
                    >
                      Terms of Use
                    </a>
                    .
                  </b>
                </Checkbox>
              </Form.Item>
              {isVirtualHybrid && (
                <Form.Item
                  name="attendanceType"
                  valuePropName="checked"
                  rules={[
                    {
                      // eslint-disable-next-line no-confusing-arrow
                      validator: () => {
                        // console.log('attendanceType', attendanceType);
                        return attendanceType
                          ? Promise.resolve()
                          : Promise.reject(new Error('Please select an option'));
                      }
                    }
                  ]}
                  style={{ textAlign: 'left', marginBottom: 0 }}
                  label={
                    <b style={{ fontWeight: 'bold' }}>
                      The program you are registering for is hybrid and can accomodate both in-person and virtual
                      attendees. Please confirm how you are planning to attend by choosing from the options below.
                    </b>
                  }
                >
                  <Checkbox
                    style={{ marginLeft: 0, lineHeight: 1.1 }}
                    onChange={onVirtualHybridChange('in-person')}
                    checked={attendanceType === 'in-person'}
                  >
                    <span style={{ fontWeight: 'bold' }}>In Person</span>
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0, lineHeight: 1.1 }}
                    onChange={onVirtualHybridChange('virtual')}
                    checked={attendanceType === 'virtual'}
                  >
                    <span style={{ fontWeight: 'bold' }}>Virtual</span>
                  </Checkbox>
                </Form.Item>
              )}
            </>
          )}

          {error && (
            <p style={{ color: 'red', textAlign: 'center' }}>
              {errorText || 'Something went wrong... please try again later!'}
            </p>
          )}
          {(isRegForm || profileFormActive) && (
            <Form.Item {...Styles.tailLayout}>
              <BorderButton
                text={isRegForm ? 'Register' : 'Save'}
                htmlType="submit"
                disabled={submitting}
                wrapperStyle={{ marginBottom: 8, maxWidth: 300, margin: 'auto' }}
                gtagtext={{ text: isRegForm ? 'Register' : 'Update' }}
                gtagid={{ id: isRegForm ? 'Register_Complete' : 'Update_Complete' }}
              />
            </Form.Item>
          )}
        </div>
      </Space>
    </Form>
  );
};

const mapStateToProps = ({ core, auth }) => {
  const { dropDownValues } = core;
  const { userFromLookup, regSessionType, isVirtualHybrid } = auth;
  return { dropDownValues, userFromLookup, regSessionType, isVirtualHybrid };
};

export default withRouter(connect(mapStateToProps, null)(RegisterAndProfileForm));
