import React from 'react';

import _ from 'lodash';

import { Form, Input, Select, Space, Button } from 'antd';

import FormWrapper from './FormWrapper';

import { BorderButton } from '../../common';

import registerAndProfileFormStyles from '../../../Styles/RegisterAndProfileForm.module.scss';

const whiteArrow = require('../../../img/authReg/white-arrow.png');

const { TextArea } = Input;

const { Option } = Select;

const PersonalInfoReg = ({
  form,
  dropDownValues,
  requiredRule,
  onValuesChange,
  onFinish,
  // isNpiLookup,
  toggleNpiLookupModal,
  // openNpiLookupModal,
  isPersonalInfoNpiSearch,
  formStyles,
  toggleRegStep
}) => {
  // const goToNextStep = () => {
  //   setStepValues(form.getFieldsValue());
  //   setRegStep('professionalInfo');
  // };

  const onButtonClick = () => {
    // validate the form and if it validates then set the regStep to professional
    form
      .validateFields()
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        isPersonalInfoNpiSearch ? toggleNpiLookupModal() : toggleRegStep('professionalInfo');
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  return (
    <FormWrapper title={'Personal Information'} progressClass="personalInfoProgress">
      <Form
        form={form}
        layout="vertical"
        name="register_form"
        // initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        // onFinishFailed={onFormFinishFailed}
        scrollToFirstError
        className="profile-reg-form"
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <div className="flex">
            <Form.Item
              label="First Name"
              name="first_name"
              required
              style={formStyles.formItemStyle}
              rules={[requiredRule]}
            >
              <Input maxLength={35} />
            </Form.Item>

            <Form.Item label="Middle Name" name="middle_name" style={formStyles.formItemStyle}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item
              label="Last Name"
              name="last_name"
              required
              style={formStyles.formItemStyle}
              rules={[requiredRule]}
            >
              <Input maxLength={35} />
            </Form.Item>

            <Form.Item label="Suffix" name="suffix" style={formStyles.formItemStyle}>
              <Input maxLength={50} />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item
              label="Email"
              name="email"
              required
              rules={[
                requiredRule,
                {
                  type: 'email',
                  message: 'The entered value is not a valid email address.'
                }
              ]}
              style={formStyles.formItemStyle}
            >
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item label="Cell Phone" name="phone" required rules={[requiredRule]} style={formStyles.formItemStyle}>
              <Input maxLength={15} />
            </Form.Item>

            {/* This is the second email field */}
          </div>

          <div className="flex">
            <Form.Item label="City" name="city" required rules={[requiredRule]} style={formStyles.formItemStyle}>
              <Input maxLength={50} />
            </Form.Item>

            <Form.Item label="State" name="state" required rules={[requiredRule]} style={formStyles.formItemStyle}>
              <Select placeholder={'Select a State'}>
                {dropDownValues.states &&
                  dropDownValues.states.map((state) => {
                    return (
                      <Option key={state.abrv} value={state.abrv}>
                        {state.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="flex">
            <div style={formStyles.formItemStyle} className="form-item-group">
              <Form.Item
                label="ZIP"
                name="zip_code"
                required
                rules={[
                  requiredRule,
                  {
                    validator: (rule, value) => {
                      if (
                        (value && value.length < 5) ||
                        (value && value.length > 10) ||
                        (value && !_.isNumber(parseInt(value.trim(), 10)))
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Enter 5 digits.');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Dietary Restrictions"
                name="dietary_restrictions"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && value.length > 50) {
                        return Promise.reject(new Error('This field can have a maximum of 50 characters'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
            </div>
            <Form.Item
              label="Comments"
              name="comments"
              style={formStyles.formItemStyle}
              rules={[
                {
                  validator: (rule, value) => {
                    if (value && value.length > 50) {
                      return Promise.reject(new Error('This field can have a maximum of 50 characters'));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <TextArea rows={6} />
            </Form.Item>
          </div>
        </Space>
        <div className="flex" style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Form.Item {...formStyles.tailLayout}>
            <Button
              className={`${registerAndProfileFormStyles['register-back-button']}`}
              onClick={() => {
                form.resetFields();
                toggleRegStep('landing');
              }}
              gtagtext={{ text: 'Register Back' }}
              gtagid={{ id: 'Register_Back' }}
            >
              Back
            </Button>
          </Form.Item>
          <Form.Item {...formStyles.tailLayout}>
            <BorderButton
              // wrapperStyle={{ margin: '0 auto', minWidth: 350 }}
              // buttonStyle={{ padding: '0 50px' }}
              wrapperClasses={`${registerAndProfileFormStyles['register-next-button']}`}
              onClick={onButtonClick}
            >
              {isPersonalInfoNpiSearch ? 'FIND MY NPI' : 'PROFESSIONAL INFORMATION'}&nbsp;&nbsp;
              <img
                src={whiteArrow}
                alt=""
                // style={{  }}
                className={`${registerAndProfileFormStyles['reg-button-next-arrow']}`}
              />
            </BorderButton>
          </Form.Item>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default PersonalInfoReg;
