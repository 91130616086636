/* eslint-disable no-unused-expressions */
import React, { useRef, useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import _ from 'lodash';

import { Row, Col, Typography, Form } from 'antd';

import SimpleNpiForm from './SimpleNpiForm';
import PersonalInfoReg from './PersonalInfoReg';
import ProfessionalInfoReg from './ProfessionalInfoReg';

import AuthHeaderBanner from '../AuthHeaderBanner';

import { loginUserSuccess } from '../../../redux/authSlice';

import { Header, BorderButton, IndicationLine, NpiLookup } from '../../common';

import SessionComponent from '../SessionComponent';

import config from '../../../config';

import styles from '../../../Styles/AuthComponent.module.scss';

const { Title, Text } = Typography;

const formStyles = {
  tailLayout: {
    wrapperCol: {
      offset: 0,
      span: 24
    }
  },
  formItemStyle: {
    width: '50%',
    marginRight: 5
  },
  tag: {
    background: '#dfeaef',
    marginTop: 10
  }
};

const requiredRule = {
  required: true,
  message: 'This field is required!'
};

const { skVeevaApiUrl } = config;

const NpiAuthComponent = ({
  dropDownValues,
  userFromLookup,
  isVirtualHybrid,
  // eslint-disable-next-line no-shadow
  loginUserSuccess
}) => {
  const [form] = Form.useForm();

  const [formValues, setFormValues] = React.useState({});

  const [error, setError] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState(null);
  const [errorText, setErrorText] = React.useState(null);
  // const [regComplete, setRegComplete] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [otherSpecialtyDisabled, setOtherSpecialtyStatus] = React.useState(true);
  const [otherDegreeDisabled, setOtherDegreeStatus] = React.useState(true);
  const [otherReferralVisible, setOtherReferralVisible] = React.useState(false);
  const [attendanceType, setAttendanceType] = React.useState(null);

  const [isNpiFieldDisabled, setIsNpiFieldDisabled] = React.useState(false);

  const [regStep, setRegStep] = useState('landing');
  const [npiLookupModalOpen, setNpiLookupModalOpen] = React.useState(false);
  const [npiLookupEmpty, setNpiLookupEmpty] = React.useState(false);
  const [isNpiLookup, setIsNpiLookup] = React.useState(false);
  const [isPersonalInfoNpiSearch, setIsPersonalInfoNpiSearch] = React.useState(false);

  const history = useHistory();
  const location = useLocation();

  const myRef = useRef(null);

  // eslint-disable-next-line import/no-named-as-default-member
  const code = config.getUrlParams('code');

  useEffect(() => {
    scrollIntoView();
  }, [myRef, regStep]);

  const scrollIntoView = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }
  };

  const toggleNpiLookupModal = () => {
    setNpiLookupModalOpen((prev) => !prev);
  };

  const toggleRegStep = (step) => {
    setFormValues(form.getFieldsValue());
    setRegStep(step);
    if (step === 'landing') {
      setIsNpiFieldDisabled(false);
      setErrorCode(null);
      setErrorText(null);
      setErrorCode(null);
      setSubmitting(false);
      form.resetFields();
      ReactGA.event({
        category: 'New Registration',
        action: 'New Registration'
      });
    }
  };

  const setNpiInfo = (hcpInfo) => {
    if (!hcpInfo) {
      setNpiLookupEmpty(true);
      setTimeout(() => {
        setNpiLookupEmpty(false);
      }, 5000);
    } else {
      console.log('hcpInfo', hcpInfo);
      // debugger;

      form.setFieldsValue({ attendee_type: 1, ...hcpInfo });
      setRegStep('personalInfo');
      setIsPersonalInfoNpiSearch(false);
      setIsNpiLookup(true);
    }
    setIsPersonalInfoNpiSearch(false);
    toggleNpiLookupModal();
  };

  const onFormFinish = (stepValues) => {
    let values = { ...formValues, ...stepValues };
    values.attendanceType = attendanceType;
    if (isNpiLookup) {
      const veevaData = {
        npi: values.npi,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        address_line_1: values.address,
        address_line_2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zip_code
      };
      // console.log('veevaData', veevaData);

      fetch(skVeevaApiUrl, {
        method: 'POST',
        body: JSON.stringify(veevaData),
        headers: { 'Content-Type': 'application/json' }
      });
    }
    setIsNpiLookup(false);

    values.code = code;
    if (values.attendeereferral === 'Other') {
      values.attendeereferral = values.other_attendeereferral || values.attendeereferral;
    }

    // eslint-disable-next-line no-param-reassign
    values = _.reduce(
      values,
      (vals, value, key) => {
        // eslint-disable-next-line no-return-assign
        vals[key] = value && _.isFunction(value.trim) ? value.trim() : value;
        return vals;
      },
      {}
    );

    // console.log('Success:', values);
    setSubmitting(true);
    fetch(`${config.baseApiUrl}/auth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    }).then((response) => {
      if (response.status !== 201) {
        if (response.status === 400) {
          setErrorText('Looks like you already have an account. Please log in with your email or NPI number');
        }
        console.log('err on registration.... ');
        setSubmitting(false);
        setError(true);
        setErrorCode(response.status);
        return;
      }
      ReactGA.event({
        category: 'New Registration',
        action: code ? `New Registration with RSVP code ${code}` : 'New Registration'
      });
      response.json().then((responseData) => {
        loginUserSuccess({
          accessToken: responseData.access,
          refreshToken: responseData.refresh,
          user: responseData.user,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${responseData.access}`
          }
        });
        setErrorText(null);
        setError(false);
        setErrorCode(null);
        setSubmitting(false);
        // history.push(`/${location.search}${code ? '&confirmed_non_affiliation=1' : ''}`);

        let nextPath = `/${location.search}${code ? '&confirmed_non_affiliation=1' : ''}`;
        if (values.attendanceType) {
          nextPath = `${nextPath}&isVirtualHybrid=${values.attendanceType === 'virtual' ? 1 : 0}`;
        }
        if (code && values.attendeereferral) {
          nextPath = `${nextPath}&attendeereferral=${values.attendeereferral}`;
        }
        history.push(nextPath);
      });
    });
  };

  const onVirtualHybridChange = (label) => {
    return (e) => {
      if (e.target.checked) {
        setAttendanceType(label);
      }
    };
  };

  const setHcpType = (hcpType) => {
    form.setFieldsValue({ attendee_type: hcpType });
    onValuesChange({ attendee_type: hcpType });
  };

  const onValuesChange = (values) => {
    // setIsEligible(!(regSessionType === 'Out-Of-Office' && values.attendee_type === 7));

    // setShowNpiLookupCopy(form.getFieldValue('attendee_type') === 1);

    // const npi = form.getFieldValue('npi');
    // if (npi && !/^\d{10}$/.test(npi)) {
    //   form.setFieldsValue({ attendee_type: 1 });
    // }

    // console.log('onValuesChange', values);

    if (values.attendee_type) {
      const isNonHCP = values.attendee_type === 6;
      setIsNpiFieldDisabled(isNonHCP);
      if (isNonHCP) {
        form.setFieldsValue({ npi: null });
      } else {
        form.setFieldsValue({ attendee_category: null });
      }
    }

    if (values.specialty !== undefined) {
      // console.log('values.specialty', values.specialty);
      if (_.includes(values.specialty, 'Other')) {
        otherSpecialtyDisabled && setOtherSpecialtyStatus(false);
      } else {
        !otherSpecialtyDisabled && setOtherSpecialtyStatus(true);
      }
    }
    if (values.degree !== undefined) {
      if (_.includes(values.degree, 'Other')) {
        otherDegreeDisabled && setOtherDegreeStatus(false);
      } else {
        !otherDegreeDisabled && setOtherDegreeStatus(true);
      }
    }
    if (values.attendeereferral !== undefined) {
      if (values.attendeereferral === 'Other') {
        !otherReferralVisible && setOtherReferralVisible(true);
      } else {
        otherReferralVisible && setOtherReferralVisible(false);
      }
    }
  };

  const renderSessionSpecificText = () => {
    return (
      <div style={{ margin: '0 auto', width: 'fit-content', maxWidth: '85%', padding: '0px 5px' }}>
        <Title id={'register-form-title'} style={{ marginBottom: 0, textAlign: 'left' }} level={4}>
          Are you visiting XCOPRI NOW for the first time?
        </Title>
        <Text
          // id={'register-form-summary'}
          style={{
            marginBottom: 5,
            marginTop: 5,
            display: 'block',
            textAlign: 'left',
            fontFamily: 'din-2014',
            color: '#54565b',
            fontSize: 18
          }}
        >
          In order to register for the specific program that interests you, please fill out the form below.
          <span
            style={{
              color: '#5B2C86',
              fontWeight: 800,
              fontSize: 16
            }}
          >
            {' '}
            You&apos;ll need to register once for full access to XCOPRI NOW.
          </span>
        </Text>
      </div>
    );
  };

  const renderLanding = () => {
    return (
      <>
        <AuthHeaderBanner />
        {code && (
          <>
            <SessionComponent code={code} isAuth />
            {renderSessionSpecificText()}
          </>
        )}
        <div className={styles['auth-wrapper']}>
          <Row justify="center">
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={24}
              align="center"
              className="register-widget-wrapper-container"
              // style={{ margin: '25px 10px', minWidth: 500 }}
            >
              <div className="widget-wrapper register-widget-wrapper">
                <Title
                  // id={'register-form-title'}
                  style={{ fontSize: 30 }}
                  level={4}
                >
                  Licensed HCP
                </Title>
                <Text style={{ fontSize: 20 }}>Enter your NPI number in the box below.</Text>
                <p style={{ fontSize: 13 }}>
                  Can&apos;t remember your NPI number?{' '}
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setIsPersonalInfoNpiSearch(true);
                      setHcpType(1);
                      setRegStep('personalInfo');
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Help me find my NPI number.
                  </span>
                </p>
                <SimpleNpiForm form={form} toggleNpiLookupModal={toggleNpiLookupModal} />
                {npiLookupEmpty && <h3 style={{ color: 'red', marginLeft: 50 }}>Sorry, no results found. </h3>}
              </div>
            </Col>
            <Col xl={8} lg={8} md={8} sm={24} align="center" className="register-widget-wrapper-container">
              <div className="widget-wrapper register-widget-wrapper">
                <Title
                  // id={'register-form-title'}
                  style={{ fontSize: 30 }}
                  level={4}
                >
                  Non-Licensed HCP
                </Title>
                <Text style={{ fontSize: 20 }}>
                  If you don&apos;t have an NPI number, you can still register.{' '}
                  <b>Get started by clicking register below.</b>
                </Text>
                <div style={{ height: 65 }} />
                <BorderButton
                  onClick={() => {
                    setHcpType(6);
                    setRegStep('personalInfo');
                  }}
                >
                  Register
                </BorderButton>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const renderPersonalInfoForm = () => {
    return (
      <>
        <Header
          title={() => (
            <div className="header-text">
              <Title className="text__white" style={{ marginBottom: 0, fontFamily: 'din-2014' }} level={2}>
                Personal Information
              </Title>
              <IndicationLine />
            </div>
          )}
          styles={styles}
        />
        <PersonalInfoReg
          form={form}
          onValuesChange={onValuesChange}
          requiredRule={requiredRule}
          formStyles={formStyles}
          dropDownValues={dropDownValues}
          isNpiLookup={isNpiLookup}
          isPersonalInfoNpiSearch={isPersonalInfoNpiSearch}
          toggleNpiLookupModal={toggleNpiLookupModal}
          // openNpiLookupModal={openNpiLookupModal}
          toggleRegStep={toggleRegStep}
        />
      </>
    );
  };

  const renderProfessionalInfoForm = () => {
    return (
      <>
        <Header
          title={() => (
            <div className="header-text">
              <Title className="text__white" style={{ marginBottom: 0, fontFamily: 'din-2014' }} level={2}>
                Professional Information
              </Title>
              <IndicationLine />
            </div>
          )}
          styles={styles}
        />
        <ProfessionalInfoReg
          form={form}
          error={error}
          errorCode={errorCode}
          errorText={errorText}
          requiredRule={requiredRule}
          submitting={submitting}
          formStyles={formStyles}
          onValuesChange={onValuesChange}
          onFormFinish={onFormFinish}
          dropDownValues={dropDownValues}
          isNpiFieldDisabled={isNpiFieldDisabled}
          onVirtualHybridChange={onVirtualHybridChange}
          isVirtualHybrid={isVirtualHybrid}
          toggleRegStep={toggleRegStep}
          setRegStep={setRegStep}
          attendanceType={attendanceType}
          isSessionSpecificRegPage={Boolean(code)}
          history={history}
        />
      </>
    );
  };

  return (
    <div style={{ margin: -50 }} ref={myRef}>
      <NpiLookup
        formValues={form.getFieldsValue()}
        modalOpen={npiLookupModalOpen}
        setNpiInfo={setNpiInfo}
        handleModalClose={toggleNpiLookupModal}
      />

      {regStep === 'landing' && renderLanding()}
      {regStep === 'personalInfo' && renderPersonalInfoForm()}
      {regStep === 'professionalInfo' && renderProfessionalInfoForm()}
    </div>
  );
};

const mapStateToProps = ({ core, auth }) => {
  const { dropDownValues } = core;
  const { userFromLookup, isVirtualHybrid } = auth;
  return { dropDownValues, userFromLookup, isVirtualHybrid };
};

export default withRouter(connect(mapStateToProps, { loginUserSuccess })(NpiAuthComponent));
